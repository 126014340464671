import React, { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { Input, Table, Button, message } from "antd";
import Menubar from "../components/WhrManager/App";
import { http } from "../services/http";
import { useTranslation } from "react-i18next";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import Navbar from "../components/navbar";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const Reports = () => {
	const { t } = useTranslation();
	const [fdata, setFData] = useState([]);
	const [wdata, setWData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const [pdfLoading, setPdfLoading] = useState(false);

	const searchInputRef = useRef(null);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const { data } = await http.get(`api/items/inventory-items`);
			const formattedData = data.data.map((item, index) => ({
				key: index,
				itemCode: item.itemCode,
				itemName: item.itemName,
				isCommited: item.isCommited,
				onHandSum: item.onHandSum,
				onOrder: item.onOrder,

				available: item.available,
				inventoryItemWhs: item.inventoryItemWhs.map((wItem, wIndex) => ({
					key: wIndex + 1,
					warehouseName: wItem.whsName,
					onHand: wItem.onHand,
				})),
			}));
			setFData(formattedData);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	}, []);

	const fetchWarehouse = useCallback(async () => {
		try {
			const { data } = await http.get(`api/warehouses`);
			const mappedItems = data.map((item) => ({
				code: item.warehouseCode,
				name: item.warehouseName,
			}));
			setWData(mappedItems);
		} catch (err) {
			console.log(err);
		}
	}, []);

	const debouncedSearch = useMemo(
		() =>
			debounce((value, dataIndex, confirm) => {
				setSearchText(value);
				setSearchedColumn(dataIndex);
				confirm();
			}, 1000),
		[]
	);

	const handleSearch = (e, confirm, dataIndex) => {
		const value = e.target.value;
		debouncedSearch(value, dataIndex, confirm);
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={searchInputRef}
					placeholder={`Search`}
					value={selectedKeys[0]}
					onChange={(e) => {
						setSelectedKeys(e.target.value ? [e.target.value] : []);
						handleSearch(e, confirm, dataIndex);
					}}
					onPressEnter={confirm}
					style={{ width: 188, marginBottom: 8, display: "block" }}
				/>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
				: "",
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInputRef.current.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	useEffect(() => {
		fetchData();
		fetchWarehouse();
	}, []);

	useEffect(() => {
		const handleBeforePrint = () => {
			handleSaveAsPDF(true);
		};

		window.addEventListener("beforeprint", handleBeforePrint);

		return () => {
			window.removeEventListener("beforeprint", handleBeforePrint);
		};
	}, []);

	const handleSaveAsPDF = async () => {
		// Assuming you have a state for loading status
		setPdfLoading(true); // Start the loader

		const element = document.getElementById("ship-product-form");

		// Calculate total scroll height of the element
		const scrollHeight = element.scrollHeight;

		try {
			const canvas = await html2canvas(element, {
				scrollY: -window.scrollY,
				height: scrollHeight,
			});
			const imgData = canvas.toDataURL("image/png");

			const pdf = new jsPDF("p", "pt", "a4");
			const pdfWidth = 300;
			const pdfHeight = 1000;
			message.info(`pdfWidth = ${pdfWidth} pdfHeigth= ${pdfHeight}`);
			pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

			// Save the PDF with a specific namek
			pdf.save("reports.pdf");

			setPdfLoading(false); // Stop the loader after PDF generation
		} catch (error) {
			console.error("Error generating PDF:", error);
			setPdfLoading(false); // Stop loader on error
		}
	};

	const columns = [
		{
			title: t("item"),
			dataIndex: "itemName",
			key: "itemName",
			width: 200,
			fixed: "left",
			...getColumnSearchProps("itemName"),
			render: (text) => <b>{text}</b>,
		},
		{
			title: t("quantityReport"),
			dataIndex: "onHandSum",
			key: "onHandSum",
			width: 150,

			render: (text) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return <span>{formattedText}</span>;
			},
		},
		{
			title: t("quantityOBC"),
			dataIndex: "isCommited",
			key: "isCommited",
			width: 150,

			render: (text) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return <span>{formattedText}</span>;
			},
		},
		{
			title: t("available"),
			dataIndex: "available",
			key: "available",
			width: 150,

			render: (text) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return <span className="font-bold text-blue-900">{formattedText}</span>;
			},
		},
		...wdata.map((item) => ({
			title: item.name,
			dataIndex: item.code,
			key: item.code,
			width: 150,
			render: (text, record) => {
				const warehouseInfo = record.inventoryItemWhs.find((w) => w.warehouseName === item.name);
				const onHand = warehouseInfo ? warehouseInfo.onHand : "0";
				const formattedonHand = new Intl.NumberFormat("fr-FR").format(onHand);
				return <span>{formattedonHand}</span>;
			},
		})),
	];

	return (
		<div className="flex w-full">
			<Menubar />
			<div id="ship-product-form" className="h-screen w-full overflow-y-auto">
				<Navbar />
				{/* <div className="flex items-center justify-between"> */}
				{/* {" "} */}
				<h1 className="font-poppins ml-4 mt-10 text-xl font-bold text-black sm:ml-10 sm:mt-14 sm:text-2xl">
					{t("reports")}
				</h1>
				{/* <Button
											onClick={handleSaveAsPDF}
											className="h-12 w-full rounded-3xl bg-[#0A4D68] text-white sm:w-[200px]"
											loading={pdfLoading}
									>
											{t("savePDF")}
									</Button> */}
				{/* </div> */}

				<div className="mt-10 w-full border-[1px] border-[#E8E8E8] sm:mt-14"></div>

				<div className="ml-10">
					<Table
						columns={columns}
						dataSource={fdata}
						pagination={false}
						className="mt-6 w-full sm:mt-10"
						rowKey="zakaz"
						scroll={{ x: 1500 }}
						sticky={true}
						loading={loading}
					/>
				</div>
			</div>
		</div>
	);
};

export default Reports;
