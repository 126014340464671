import { Modal, Input, Table, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const ReturnedItemsModal = ({ visible, data, onClose }) => {
	const { t } = useTranslation();
	const department = useSelector((state) => state.main.department);
	const formattedSumma = new Intl.NumberFormat("fr-FR").format(data?.summa);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedComments, setSelectedComments] = useState("");

	const handleSelectClick = () => {
		setSelectedComments(data.comments);
		setIsModalVisible(true);
	};

	const handleModalOk = () => {
		setIsModalVisible(false);
	};

	const handleModalCancel = () => {
		setIsModalVisible(false);
	};

	if (!data) return null;

	const columns = [
		{
			title: t("item"),
			dataIndex: "maxsulot",
			key: "maxsulot",
		},
		{
			title: t("quantityRemoved"),
			dataIndex: "quantity",
			key: "quantity",
			render: (text, record) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return `${formattedText} `;
			},
		},
		{
			title: t("unitMsr"),
			dataIndex: "measureUnit",
			key: "measureUnit",
		},

		{
			title: t("whs"),
			dataIndex: "whsName",
			key: "whsName",
		},
		{
			title: t("price"),
			dataIndex: "price",
			key: "price",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return (
					<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{formattedText} {data?.docCurrency}
					</span>
				);
			},
		},

		{
			title: t("returnTotal"),
			dataIndex: "lineTotal",
			key: "lineTotal",
			render: (text, record, index) => {
				const formattedText = new Intl.NumberFormat("fr-FR").format(text);
				return (
					<span className={`${data.lineStatus[index] === "C" ? "text-slate-500" : ""}`}>
						{formattedText} {data?.docCurrency}
					</span>
				);
			},
		},
	];

	if (
		String(department).toLowerCase() === "royal" ||
		String(department).toLowerCase() === "artel"
	) {
		columns.push(
			{
				title: t("u_BonusPerQty"),
				dataIndex: "u_BonusPerQty",
				key: "u_BonusPerQty",
				render: (text, record, index) => {
					const formattedText = new Intl.NumberFormat("fr-FR").format(text);
					return (
						<span className={data.lineStatus[index] === "C" ? "text-slate-500" : ""}>
							{formattedText} {data?.docCurrency}
						</span>
					);
				},
			},
			{
				title: t("u_BonusSumm"),
				dataIndex: "u_BonusSumm",
				key: "u_BonusSumm",
				render: (text, record, index) => {
					const formattedText = new Intl.NumberFormat("fr-FR").format(text);
					return (
						<span className={data.lineStatus[index] === "C" ? "text-slate-500" : ""}>
							{formattedText} {data?.docCurrency}
						</span>
					);
				},
			}
		);
	}

	const tableData = data.maxsulot.map((item, index) => ({
		key: index,
		maxsulot: item,
		quantity: data.quantity[index],
		measureUnit: data.ugpName[index],
		price: data.price[index],
		lineTotal: data.lineTotal[index],
		inventoryQuantity: data.inventoryQuantity[index],
		ugpName: data.ugpName[index],
		whsName: data.whsName[index],
		u_BonusPerQty: data.u_BonusPerQty[index],
		u_BonusSumm: data.u_BonusSumm[index],
	}));

	return (
		<Modal
			title={
				<h1 className="font-nunito text-xl font-extrabold text-[#000000]">
					{t("order")} № {data.docNum}
				</h1>
			}
			visible={visible}
			onCancel={onClose}
			footer={null}
			closeIcon={<CloseOutlined />}
			width="75%"
			style={{ height: "75%" }}
			bodyStyle={{ height: "calc(100% - 55px)", overflowY: "auto" }}
		>
			<div className="w-full px-4 sm:px-14">
				<div className="w-full">
					<div className="mt-10 flex flex-col items-center justify-between sm:flex-row">
						<div className="flex flex-col gap-3">
							<p className="font-nunito">{t("mijoz")}</p>
							<Input
								type="text"
								value={data.mijoz}
								className="flex h-12 w-[200px] items-center justify-center sm:w-[250px]"
								style={{ color: "black" }}
								disabled
							/>
						</div>
						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p className="font-nunito">{t("date")}</p>
							<Input value={data.sana} style={{ color: "black" }} disabled />
						</div>
					</div>
					<Table
						columns={columns}
						dataSource={tableData}
						pagination={false}
						className="border-1 mt-12 border-black"
						scroll={{ x: "100%" }}
					/>

					<div className="mt-5 flex items-center justify-end gap-5">
						{String(department).toLowerCase() === "royal" ||
						String(department).toLowerCase() === "artel" ? (
							<div className="flex flex-col gap-3">
								<p>{t("returnTotal")}</p>
								<Input
									type="text"
									value={`${formattedSumma} ${data?.docCurrency}`}
									style={{ color: "black" }}
									className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
									disabled
								/>
							</div>
						) : (
							""
						)}
						<div className="flex flex-col gap-3">
							<p>{t("returnedTotal")}</p>
							<Input
								type="text"
								value={`${data.docTotalQuantity} шт`}
								style={{ color: "black" }}
								className="flex h-10 w-full items-center justify-center border-[1px] border-[#D0D0D0] active:border-[#D0D0D0] sm:w-[200px]"
								disabled
							/>
						</div>
					</div>
					<div className="mt-10 flex flex-col items-center justify-between gap-3 sm:flex-row">
						<div className="flex flex-col gap-3">
							<p>{t("comments")}</p>
							<Select
								value={data.comments}
								className="flex h-12 w-full items-center justify-center sm:w-[250px]"
								style={{ color: "black" }}
								disabled
								onClick={handleSelectClick}
							/>
							<Modal
								title={t("comments")}
								visible={isModalVisible}
								onOk={handleModalOk}
								onCancel={handleModalCancel}
							>
								<p>{selectedComments}</p>
							</Modal>
						</div>

						<div className="mt-4 flex flex-col gap-3 sm:mt-0">
							<p>{t("responsiblePerson")}</p>
							<Input
								type="text"
								value={data.yaratdi}
								style={{ color: "black" }}
								disabled
								className="flex h-10 w-[200px] items-center justify-center border-[1px] border-[#D0D0D0]"
							/>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ReturnedItemsModal;
