import moment from "moment";

export const aggregateDocuments = (data) => {
	const aggregatedData = data.data.reduce((acc, entry) => {
		entry.documentLines.forEach((line) => {
			const key = `${entry.docEntry}`;
			if (!acc[key]) {
				acc[key] = {
					docEntry: entry.docEntry,
					docNum: entry.docNum,
					cardCode: entry.cardCode,
					mijoz: entry.cardName,
					sana: moment(entry.docDueDate).format("DD-MM-YYYY"),
					docDueDate: entry.docDueDate,
					summa: entry.docTotal,
					ownerCode: entry.ownerCode,
					docTotalQuantity: entry.docTotalQuantity,
					salesPersonCode: entry.salesPersonCode,
					yaratdi: entry.slpName,
					docCur: entry.docCur,
					comments: entry.comments,
					u_BonusTotal: entry.u_BonusTotal,
					discount: entry.discount,
					docCurrency: entry.docCurrency,

					itemCode: [],
					lineNum: [],
					lineStatus: [],
					maxsulot: [],
					baseType: [],
					baseEntry: [],
					baseLine: [],
					quantity: [],
					whsCode: [],
					whsName: [],
					measureUnit: [],
					price: [],
					inventoryQuantity: [],
					discountPercent: [],
					lineTotal: [],
					currency: [],
					openInvQty: [],
					openQty: [],
					ugpName: [],
					u_BonusPerQty: [],
					u_BonusSumm: [],
					u_Category: [],
					u_ItemGroup: [],
				};
			}
			acc[key].itemCode.push(line.itemCode);
			acc[key].lineStatus.push(line.lineStatus);
			acc[key].maxsulot.push(line.itemDescription);
			acc[key].baseType.push(line.baseType);
			acc[key].baseEntry.push(line.baseEntry);
			acc[key].baseLine.push(line.baseLine);
			acc[key].quantity.push(line.quantity);
			acc[key].whsCode.push(line.warehouseCode);
			acc[key].whsName.push(line.whsName);
			acc[key].lineNum.push(line.lineNum);
			acc[key].measureUnit.push(line.measureUnit);
			acc[key].price.push(line.price);
			acc[key].inventoryQuantity.push(line.inventoryQuantity);
			acc[key].discountPercent.push(line.discountPercent);
			acc[key].lineTotal.push(line.lineTotal);
			acc[key].currency.push(line.currency);
			acc[key].openInvQty.push(line.openInvQty);
			acc[key].openQty.push(line.openQty);
			acc[key].ugpName.push(line.ugpCode);
			acc[key].u_BonusPerQty.push(line.u_BonusPerQty);
			acc[key].u_Category.push(line.u_Category);
			acc[key].u_ItemGroup.push(line.u_ItemGroup);
			acc[key].u_BonusSumm.push(line.u_BonusSumm);
		});
		return acc;
	}, {});

	const aggregatedArray = Object.values(aggregatedData);

	aggregatedArray.sort((a, b) => b.docEntry - a.docEntry);

	return aggregatedArray;
};
export const aggregateDocumentsWithoutData = (data) => {
	const aggregatedData = data.reduce((acc, entry) => {
		entry.documentLines.forEach((line) => {
			const key = `${entry.docEntry}`;
			if (!acc[key]) {
				acc[key] = {
					docEntry: entry.docEntry,
					docNum: entry.docNum,
					cardCode: entry.cardCode,
					docCurrency: entry.docCurrency,
					mijoz: entry.cardName,
					sana: moment(entry.docDueDate).format("DD-MM-YYYY"),
					docDueDate: entry.docDueDate,
					summa: entry.docTotal,
					ownerCode: entry.ownerCode,
					docTotalQuantity: entry.docTotalQuantity,
					salesPersonCode: entry.salesPersonCode,
					yaratdi: entry.slpName,
					docCur: entry.docCur,
					comments: entry.comments,
					u_BonusTotal: entry.u_BonusTotal,
					itemCode: [],
					lineNum: [],
					lineStatus: [],
					maxsulot: [],
					baseType: [],
					baseEntry: [],
					baseLine: [],
					quantity: [],
					whsCode: [],
					whsName: [],
					measureUnit: [],
					price: [],
					inventoryQuantity: [],
					discountPercent: [],
					lineTotal: [],
					currency: [],
					openInvQty: [],
					openQty: [],
					ugpName: [],
					u_BonusPerQty: [],
					u_BonusSumm: [],
					u_Category: [],
					u_ItemGroup: [],
					
				};
			}
			acc[key].itemCode.push(line.itemCode);
			acc[key].lineStatus.push(line.lineStatus);
			acc[key].maxsulot.push(line.itemDescription);
			acc[key].baseType.push(line.baseType);
			acc[key].baseEntry.push(line.baseEntry);
			acc[key].baseLine.push(line.baseLine);
			acc[key].quantity.push(line.quantity);
			acc[key].whsCode.push(line.warehouseCode);
			acc[key].whsName.push(line.whsName);
			acc[key].lineNum.push(line.lineNum);
			acc[key].measureUnit.push(line.measureUnit);
			acc[key].price.push(line.price);
			acc[key].inventoryQuantity.push(line.inventoryQuantity);
			acc[key].discountPercent.push(line.discountPercent);
			acc[key].lineTotal.push(line.lineTotal);
			acc[key].currency.push(line.currency);
			acc[key].openInvQty.push(line.openInvQty);
			acc[key].openQty.push(line.openQty);
			acc[key].ugpName.push(line.ugpCode);
			acc[key].u_BonusPerQty.push(line.u_BonusPerQty);
			acc[key].u_Category.push(line.u_Category);
			acc[key].u_ItemGroup.push(line.u_ItemGroup);
			acc[key].u_BonusSumm.push(line.u_BonusSumm);
		});
		return acc;
	}, {});

	const aggregatedArray = Object.values(aggregatedData);

	aggregatedArray.sort((a, b) => b.docEntry - a.docEntry);

	return aggregatedArray;
};

export const aggregateDocumentsWithDriver = (data) => {
	const aggregatedData = data.data.reduce((acc, entry) => {
		entry.documentLines.forEach((line) => {
			const key = `${entry.docEntry}`;
			if (!acc[key]) {
				acc[key] = {
					docEntry: entry.docEntry,
					docNum: entry.docNum,
					cardCode: entry.cardCode,
					mijoz: entry.cardName,
					sana: moment(entry.docDueDate).format("DD-MM-YYYY"),
					docDueDate: entry.docDueDate,
					summa: entry.docTotal,
					ownerCode: entry.ownerCode,
					salesPersonCode: entry.salesPersonCode,
					docTotalQuantity: entry.docTotalQuantity,
					driver: `${entry.driverFirstName} ${entry.driverLastname}`,
					yaratdi: entry.slpName,
					docCur: entry.docCur,
					comments: entry.comments,
					u_BonusTotal: entry.u_BonusTotal,
					docStatus: entry.docStatus,
					discount: entry.discount,
					docCurrency: entry.docCurrency,
					itemCode: [],
					maxsulot: [],
					baseType: [],
					baseEntry: [],
					lineNum: [],
					baseLine: [],
					quantity: [],
					whsCode: [],
					lineStatus: [],
					whsName: [],
					measureUnit: [],
					price: [],
					inventoryQuantity: [],
					discountPercent: [],
					lineTotal: [],
					currency: [],
					openInvQty: [],
					openQty: [],
					ugpName: [],
					u_BonusPerQty: [],
					u_Category: [],
					u_ItemGroup: [],
					u_BonusSumm: [],
				};
			}
			acc[key].itemCode.push(line.itemCode);
			acc[key].lineStatus.push(line.lineStatus);
			acc[key].maxsulot.push(line.itemDescription);
			acc[key].baseType.push(line.baseType);
			acc[key].baseEntry.push(line.baseEntry);
			acc[key].baseLine.push(line.baseLine);
			acc[key].lineNum.push(line.lineNum);
			acc[key].quantity.push(line.quantity);
			acc[key].whsCode.push(line.warehouseCode);
			acc[key].whsName.push(line.whsName);
			acc[key].measureUnit.push(line.measureUnit);
			acc[key].price.push(line.price);
			acc[key].inventoryQuantity.push(line.inventoryQuantity);
			acc[key].u_BonusSumm.push(line.u_BonusSumm);

			acc[key].discountPercent.push(line.discountPercent);
			acc[key].lineTotal.push(line.lineTotal);
			acc[key].currency.push(line.currency);
			acc[key].openInvQty.push(line.openInvQty);
			acc[key].u_BonusPerQty.push(line.u_BonusPerQty);
			acc[key].u_Category.push(line.u_Category);
			acc[key].ugpName.push(line.ugpCode);
			acc[key].u_ItemGroup.push(line.u_ItemGroup);

			acc[key].openQty.push(line.openQty);
		});
		return acc;
	}, {});

	const aggregatedArray = Object.values(aggregatedData);

	aggregatedArray.sort((a, b) => b.docEntry - a.docEntry);

	return aggregatedArray;
};

export const aggregateDocumentsSales = (data) => {
	const aggregatedData = data.data.reduce((acc, entry) => {
		entry.documentLines.forEach((line) => {
			const key = `${entry.docEntry}`;
			if (!acc[key]) {
				acc[key] = {
					docEntry: entry.docEntry,
					docNum: entry.docNum,
					cardCode: entry.cardCode,
					mijoz: entry.cardName,
					sana: moment(entry.docDueDate).format("DD-MM-YYYY"),
					docDueDate: entry.docDueDate,
					summa: entry.docTotal,
					ownerCode: entry.ownerCode,
					salesPersonCode: entry.salesPersonCode,
					docTotalQuantity: entry.docTotalQuantity,
					driver: `${entry.driverFirstName} ${entry.driverLastname}`,
					yaratdi: entry.slpName,
					u_Father_DocNum: entry.u_Father_DocNum,
					u_Driver: entry.u_Driver,
					docCur: entry.docCur,
					comments: entry.comments,
					u_BonusTotal: entry.u_BonusTotal,
					itemCode: [],
					maxsulot: [],
					baseType: [],
					baseEntry: [],
					lineNum: [],
					lineStatus: [],
					baseLine: [],
					quantity: [],
					whsCode: [],
					whsName: [],
					measureUnit: [],
					price: [],
					inventoryQuantity: [],
					discountPercent: [],
					lineTotal: [],
					currency: [],
					openInvQty: [],
					openQty: [],
					ugpName: [],
					u_BonusPerQty: [],
					u_Category: [],
					u_ItemGroup: [],
					u_BonusSumm: [],
				};
			}
			acc[key].itemCode.push(line.itemCode);
			acc[key].maxsulot.push(line.itemDescription);
			acc[key].baseType.push(line.baseType);
			acc[key].baseEntry.push(line.baseEntry);
			acc[key].baseLine.push(line.baseLine);
			acc[key].lineNum.push(line.lineNum);
			acc[key].lineStatus.push(line.lineStatus);
			acc[key].quantity.push(line.quantity);
			acc[key].whsCode.push(line.warehouseCode);
			acc[key].whsName.push(line.whsName);
			acc[key].measureUnit.push(line.measureUnit);
			acc[key].u_BonusSumm.push(line.u_BonusSumm);
			acc[key].price.push(line.price);
			acc[key].inventoryQuantity.push(line.inventoryQuantity);
			acc[key].discountPercent.push(line.discountPercent);
			acc[key].lineTotal.push(line.lineTotal);
			acc[key].currency.push(line.currency);
			acc[key].openInvQty.push(line.openInvQty);
			acc[key].openQty.push(line.openQty);
			acc[key].u_BonusPerQty.push(line.u_BonusPerQty);
			acc[key].u_Category.push(line.u_Category);
			acc[key].ugpName.push(line.ugpCode);
			acc[key].u_ItemGroup.push(line.u_ItemGroup);
		});
		return acc;
	}, {});

	const aggregatedArray = Object.values(aggregatedData);

	aggregatedArray.sort((a, b) => b.docEntry - a.docEntry);

	return aggregatedArray;
};

export const formattedShippedReports = (data) => {
	return data.map((item, index) => ({
		dscription: item.dscription,
		itmsGrpNam: item.itmsGrpNam,
		totalQuantity: item.totalQuantity,
		unitMsr: item.unitMsr,
		totalInvQty: item.totalInvQty,
		ugpName: item.ugpName,
		docDueDate: item.docDueDate,
	}));
};
