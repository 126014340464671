import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Input, DatePicker, Select } from "antd";
import Menubar from "../../components/WhrManager/App";
import { http } from "../../services/http";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import Highlighter from "react-highlight-words";
import { useTranslation } from "react-i18next";
import Header from "../../components/header";
import { column } from "../../utils/columns";
import FinishedSalesModal from "../../components/WhrManager/finishedSalesModal";
import { aggregateDocumentsWithDriver } from "../../utils/document";
import moment from "moment";
import { getToday } from "../../utils/getDays";
const { RangePicker } = DatePicker;

const FinishedSales = () => {
	const { t } = useTranslation();
	const [fdata, setFData] = useState([]);
	const [ddata, setDdata] = useState([]);

	const [loading, setLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(0);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedRowData, setSelectedRowData] = useState(null);
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");
	const formattedDate = getToday();

	const [driver, setDriver] = useState("");
	const [docNum, setDocNum] = useState("");
	const [cardName, setCardName] = useState("");
	const [slpName, setSlpName] = useState("");
	const [startDate, setStartDate] = useState(moment().startOf("month").format("YYYY-MM-DD"));
	const [endDate, setEndDate] = useState(formattedDate);
	// const [docStatus, setDocStatus] = useState("");

	const fetchData = useCallback(
		async (page, ScardName, SdocNum, SslpName, SstartDate, SendDate, Sdriver) => {
			try {
				let url = " ";
				setLoading(true);
				if (cardName === "" && docNum === "" && slpName === "" && startDate === "") {
					url = `api/sotuvlar/yakunlangan-sotuvlar?cardName=${ScardName}&docNum=${SdocNum}&slpName=${SslpName}&startDate=${SstartDate}&endDate=${SendDate}&pageToken=${page}&driver=${Sdriver}`;
				} else {
					url = `api/sotuvlar/yakunlangan-sotuvlar?cardName=${ScardName}&docNum=${SdocNum}&slpName=${SslpName}&startDate=${SstartDate}&endDate=${SendDate}&driver=${Sdriver}`;
				}
				const { data } = await http.get(url);

				const formattedData = aggregateDocumentsWithDriver(data);

				const hasMore = formattedData.length === 10 ? true : false;
				console.log(hasMore);
				setFData(formattedData);
				setHasMoreData(hasMore);
			} catch (error) {
				console.log(error);
			}
			setLoading(false);
		},
		[cardName, docNum, startDate, endDate, slpName]
	);

	const fetchDrivers = async () => {
		try {
			const { data } = await http.get(`api/drivers`);

			const formattedData = data.map((item) => ({
				name: `${item.firstName} ${item.lastName}`,
				value: item.employeeID,
			}));

			setDdata(formattedData);
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(() => {
		fetchDrivers();
	}, []);

	useEffect(() => {
		fetchData(currentPage, cardName, docNum, slpName, startDate, endDate, driver);
	}, [fetchData, currentPage, cardName, docNum, slpName, startDate, endDate, driver]);

	const handleNextPage = () => {
		if (hasMoreData) {
			setCurrentPage((prevPage) => prevPage + 1);
		}
	};

	const handlePreviousPage = () => {
		if (currentPage > 0) {
			setCurrentPage((prevPage) => prevPage - 1);
		}
	};

	const handleRowClick = (record) => {
		setSelectedRowData(record);
		setIsModalVisible(true);
	};

	let searchInput = null;

	const handleDateRangeChange = (dates, confirm, dataIndex) => {
		debouncedSearch(dates, dataIndex, confirm);
	};

	const handleStartDate = (e) => {
		setStartDate(e.target.value);
	};

	const handleEndDate = (e) => {
		setEndDate(e.target.value);
	};

	const debouncedSearch = useMemo(
		() =>
			debounce((value, dataIndex, confirm) => {
				if (dataIndex === "mijoz") {
					if (value) {
						setCardName(value);
					} else {
						setCardName("");
					}
				}
				// else if (dataIndex === "docStatus") {
				// 	if (value) {
				// 		setCurrentPage(0);
				// 		setDocStatus(value);
				// 	} else {
				// 		setDocStatus("");
				// 	}
				// }
				else if (dataIndex === "docNum") {
					if (value) {
						setDocNum(value);
					} else {
						setDocNum("");
					}
				} else if (dataIndex === "yaratdi") {
					if (value) {
						setSlpName(value.toUpperCase());
					} else {
						setSlpName("");
					}
				} else if (dataIndex === "sana") {
					if (value) {
						const formattedStartDate = new Date(value[0]).toLocaleString();
						const formattedEndDate = new Date(value[1]).toLocaleString();
						console.log("formattedStartDate : " + formattedStartDate);
						console.log("formattedEndDate : " + formattedEndDate);

						if (formattedStartDate && formattedEndDate) {
							setStartDate(moment(formattedStartDate).format("YYYY-MM-DD"));
							setEndDate(moment(formattedEndDate).format("YYYY-MM-DD"));
						}
					} else {
						setStartDate("");
						setEndDate("");
					}
				} else {
				}
				confirm();
			}, 2000),
		[fetchData, currentPage]
	);

	const getColumnSearchProps = (dataIndex) => {
		if (dataIndex === "sana") {
			return {
				filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
					<div style={{ padding: 8 }}>
						<RangePicker
							value={selectedKeys && selectedKeys[0] ? selectedKeys : []}
							onChange={(dates) => {
								setSelectedKeys(dates ? dates : []);
								handleDateRangeChange(dates, confirm, dataIndex);
							}}
							style={{ marginBottom: 8, display: "block" }}
							disabledDate={(current) => current && current > moment().endOf("day")}
						/>
					</div>
				),
				filterIcon: (filtered) => (
					<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
				),
				onFilter: (value, record) => {
					if (Array.isArray(value) && value.length === 2) {
						const [startDate, endDate] = value;
						const recordDate = moment(record[dataIndex]);
						return recordDate.isBetween(startDate, endDate, "[]");
					}
					return true;
				},
				render: (text) => text,
			};
		}
		return {
			filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
				<div style={{ padding: 8 }}>
					<Input
						ref={(node) => {
							searchInput = node;
						}}
						placeholder={`${t("search")} ${dataIndex}`}
						value={selectedKeys[0]}
						onChange={(e) => {
							setSelectedKeys(e.target.value ? [e.target.value] : []);
							handleSearch(e, confirm, dataIndex);
						}}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
				</div>
			),
			filterIcon: (filtered) => (
				<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
			),
			onFilter: (value, record) =>
				record[dataIndex]
					? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
					: "",
			onFilterDropdownOpenChange: (visible) => {
				if (visible) {
					setTimeout(() => searchInput.select(), 100);
				}
			},
			render: (text) =>
				searchedColumn === dataIndex ? (
					<Highlighter
						highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
						searchWords={[searchText]}
						autoEscape
						textToHighlight={text ? text.toString() : ""}
					/>
				) : (
					text
				),
		};
	};
	const handleSearch = (e, confirm, dataIndex) => {
		const value = e.target.value;
		debouncedSearch(value, dataIndex, confirm);
	};
	const columns = column(t, getColumnSearchProps, true).map((col) => {
		if (col.dataIndex === "summa") {
			return {
				...col,
				render: (text, record) => {
					const formattedText = new Intl.NumberFormat("fr-FR").format(record.summa);
					return `${formattedText} ${record.docCurrency}`;
				},
			};
		}
		if (col.dataIndex === "docNum") {
			return {
				...col,
				title: t("numFinish"),
			};
		}

		if (col.dataIndex === "driver") {
			if (col.dataIndex === "driver") {
				return {
					...col,
					filterDropdown: () => (
						<Select
							allowClear
							style={{ width: 250 }}
							placeholder="Select Description"
							value={driver}
							onChange={(value) => {
								setCurrentPage(0);
								setDriver(value !== undefined ? value : "");
							}}
						>
							<Select.Option key={""} value={""}>
								All
							</Select.Option>
							{ddata.map((item) => (
								<Select.Option key={item.value} value={item.value}>
									{item.name}
								</Select.Option>
							))}
						</Select>
					),
					onFilter: (value, record) => record.dscription === value,
				};
			}
		}

		return col;
	});

	return (
		<div className="flex h-full w-full overflow-y-auto">
			<Menubar />
			<Header
				title={"finishedSales"}
				currentPage={currentPage + 1}
				handlePreviousPage={handlePreviousPage}
				handleNextPage={handleNextPage}
				hasMoreData={hasMoreData}
				columns={columns}
				fdata={fdata}
				loading={loading}
				handleRowClick={handleRowClick}
				onStartDateChange={handleStartDate}
				onEndDateChange={handleEndDate}
				startDate={startDate}
				endDate={endDate}
				formattedDate={formattedDate}
			/>
			<FinishedSalesModal
				visible={isModalVisible}
				data={selectedRowData}
				onClose={() => setIsModalVisible(false)}
			/>
		</div>
	);
};

export default FinishedSales;
